import * as React from 'react';
import {graphql, Link as GatsbyLink} from 'gatsby';
import { ThemeProvider } from '@mui/material/styles';
import { 
    Backdrop,
	Box,
    Button,
    CircularProgress,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormControl,
    FormControlLabel,
	Grid,
    IconButton,
    Radio,
    RadioGroup,
    Select,
    Slider,
    Snackbar,
    Switch,
    MenuItem,
    TextField,
    Tooltip,
	Typography,
    Alert
} from '@mui/material';
import { navigate } from "gatsby";
import DraggableList from "../components/DraggableList"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import GameDraggableListItem from "../components/GameDraggableListItem"
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import theme from '../styles/theme';
import { auth, db } from '../../firebase-info';
import { onAuthStateChanged } from "firebase/auth";
import useLocalStorageState from 'use-local-storage-state'
import BeGeneralPage from '../components/be-general-page';
import supportedLanguages from '../utils/supportedLanguages'
import { QUESTIONS_DEFAULT_PARAMS } from '../components/game-forms/be-questions-form';
import {
	collection,
	doc,
	getDoc,
    setDoc,
    Timestamp,
	getDocs,
	query,
	where
} from "firebase/firestore";
import { AuthContext, GamesContext } from '../contexts/contexts.js';
import { getListOfElementsInInterval } from '../utils/utils.js';


const reorder = (
    list,
    startIndex,
    endIndex
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

// markup
const CheckoutExperiment = (props) => {
    const { i18n } = useTranslation();

    const authContextValue = React.useContext(AuthContext);
    const [experiment, setExperiment] = useLocalStorageState("experiment");
    const [gamePosition, setGamePosition] = useLocalStorageState("gamePosition", {defaultValue: undefined});
    const [editing, setEditing] = useLocalStorageState("editing", {defaultValue: false});
    const [questionsGame, setQuestionsGame] = React.useState([]);
    const [sortableGames, setSortableGames] = React.useState([]);
    const [discardDialogOpen, setDiscardDialogOpen] = React.useState(false)
	const [loading, setLoading] = React.useState(true)
    const [questionsDefault, setQuestionsDefault] = React.useState(null)
    
    const [probPaymentAllParticipants, setProbPaymentAllParticipants] = React.useState(experiment?.probabilisticPayment?.paidParticipants?.length === 0);
    const [probPaymentParticipantsRate, setProbPaymentParticipantsRate] = React.useState((experiment?.probabilisticPayment?.paidParticipants?.length * 100.0 / experiment?.maxParticipants) || 10);

    const [probPaymentAllGames, setProbPaymentAllGames] = React.useState(experiment?.probabilisticPayment?.paidGames?.length === 0);

    const [showSnackPayGame, setShowSnackPayGame] = React.useState(false)

    const gamesInfo = React.useContext(GamesContext);

    //const editing = props.location.state?.editing;
    const fromAnother = props.location.state?.fromAnother;

    async function loadQuestionsData() {
        const questionsRef = doc(db, "games", "questions");
        const questionsData = (await getDoc(questionsRef))?.data();
        
        setQuestionsDefault({
            ...questionsData, 
            pinned: true, 
            listId: `${questionsData.id}_1`,
            instance: 1,
            params: {...QUESTIONS_DEFAULT_PARAMS}
        });
    } 

    const splitGames = () => {
        const isGamePaidFnt = (game) => {
            if(experiment?.probabilisticPayment?.paidGames?.length === 0){
                return game.isPaid;
            }
            else{
                return !(!experiment?.probabilisticPayment?.paidGames?.find(elem => elem === game.listId));
            }
        }

        if(experiment){
            if(experiment?.games){
                setQuestionsGame(experiment.games?.filter(game => game.id === 'questions').map(game => ({...game, pinned: true})));
                setSortableGames(experiment.games?.filter(game => game.id !== 'questions').map(game => {
                    const payable = isGamePaidFnt(game);
                    if(game.pinned === undefined){
                        return {...game, payable , pinned: true}
                    }
                    else{
                        return {...game, payable};
                    }
                }));
                
            }
            
            setLoading(false);
        }
        else{
            navigate("/dashboard");
            setLoading(false);
			return;
        }
    }
  
    React.useEffect(() => {
        const existingQuestions = experiment?.games?.filter(game => game.id === 'questions').map(game => ({...game, pinned: true}))
        if(!existingQuestions?.length){
            if(questionsDefault){
                setQuestionsGame([questionsDefault]);
            }
        }
    }, [questionsDefault])

    const paidGames = React.useMemo(() => {
        return experiment?.games.reduce((prev, curr) => {
            if (curr.isPaid) {
                prev++;
            }

            return prev;
        }, 0);

    }, [experiment?.games.length])

	React.useEffect(() => {
        loadQuestionsData();
        splitGames();
	}, []);

    const editGameParameters = (game, index) => {
        //console.log(`Editing game ${game.title}`);
        //console.log(`at position ${index}`);

        if (game.parametrizable) {
            setGamePosition(index);
            navigate(`/gameCreation/${game.parametrizable}`);
        }
    }

    async function saveExperiment() {
		console.log("Saving experiment");

        const now = new Date();
        // First we purge the games and remove all unnecessary info
        experiment.games = experiment?.games.map((g) => ({
            id: g.id,
            params: g.params || [],
            pinned: g.pinned,
            listId: g.listId,
            instance: g.instance,
            payable: g.payable || false,
        }))
        
        try{
            let expRef
            if(experiment?.id){
                // Existing experiment, update data
                expRef = doc(collection(db, "experiments"), experiment?.id);
            }
            else{
                // New experiment, insert data
                expRef = doc(collection(db, "experiments"));
            }
            
            experiment["id"]  = expRef.id;
            experiment["owner"] = authContextValue?.userRef;
            experiment["lastModificationDate"] = Timestamp.fromDate(now);

            // Probabilistic payment by participants
            if (probPaymentAllParticipants){
                experiment.probabilisticPayment.paidParticipants = [];
            }
            else{
                const paidParticipants = getListOfElementsInInterval(1, experiment.maxParticipants, experiment?.maxParticipants*(probPaymentParticipantsRate/100.0))
                paidParticipants.sort((a,b) => {return (a > b) ? 1 : -1;});
                console.log(paidParticipants);
                experiment.probabilisticPayment.paidParticipants = paidParticipants
            }

            // Probabilistic payment by games
            if(probPaymentAllGames){
                experiment.probabilisticPayment.paidGames = [];
            }
            else{
                const paidGames = experiment.games?.filter(game => game.payable === true).map(game => {
                        return game.listId;
                });
                console.log(paidGames);
                experiment.probabilisticPayment.paidGames = paidGames;
            }
            setDoc(expRef, experiment);
            navigate(`/myExperiments`, { state: { 
                success: true
            }});
            setExperiment(null);
            setEditing(false);
        }
        catch(e){
            console.log(e);
            setExperiment(null);
            setEditing(false);
            navigate("/dashboard", props.location.state);
        }

	};

    function discardExperiment() {
        console.log("Discarding experiment")
        setExperiment(null);
        setEditing(false);  // This doesn't affect the following if
        if(editing){
            navigate(-1, props.location.state);
        }
        else{
            navigate("/dashboard", props.location.state);
        }
        
    }

    const onDragEnd = ({ destination, source }) => {
        // dropped outside the list
        if (!destination) return;
    
        const newItems = reorder(sortableGames, source.index, destination.index);
    
        setSortableGames(newItems);
      };

    React.useEffect(() => {
        const exp = {...experiment}
        if(sortableGames?.length){
            exp.games = [...questionsGame, ...sortableGames];
            setExperiment(exp);
        }
    }, [questionsGame, sortableGames]);

    /*
    React.useEffect(() => {
        experiment?.name = expName;
        experiment?.language = expLanguage;
        experiment?.maxParticipants = expMaxParticipants;
        experiment?.externalCodeChoice = expExtCodeChoice;
        experiment?.completionCode = expCompletionCode;
        experiment?.consentText = expConsentText;
        experiment?.askEmail = expAskForEmail;
        experiment?.payRandom = expProbabilisticPayment;
        experiment?.openToParticipants = expOpenToParticipants;

        setExperiment(experiment);
        }, [
            expName,
            expLanguage, 
            expMaxParticipants,
            expExtCodeChoice,
            expCompletionCode,
            expConsentText,
            expAskForEmail,
            expProbabilisticPayment,
            expOpenToParticipants
        ]);
*/
	const { t } = useTranslation();

	return (
		<ThemeProvider theme={theme}>
			<main>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <BeGeneralPage title={t("Check your experiment")}>
                    {
                        (authContextValue?.userData && !authContextValue?.userData.isAdminValidated) ?
                            <Alert severity="warning"><Trans>Your account hasn't been validated yet, you can select games, but you won't be able to create experiments until validation</Trans></Alert>
                        :
                            null
                    }
                    <Snackbar 
                        open={showSnackPayGame} 
                        autoHideDuration={3000} 
                        onClose={()=>{setShowSnackPayGame(false)}}
                        anchorOrigin={{ vertical:'top', horizontal:'center' }}
                    >
                        <Alert onClose={()=>{setShowSnackPayGame(false)}} severity="warning" sx={{ width: '100%' }}>
                        <Trans>You must uncheck 'Pay all games' to allow editing this feature</Trans>
                        </Alert>
                    </Snackbar>
                    <Grid container spacing={2}>
                        <Grid item xs={6} key={`selected-games`}>
                            <Grid container spacing={2}>
                                {/* EXPERIMENT NAME */}
                                <Grid item xs={12} key="experiment-name">
                                    <Typography fontFamily={"Noto Sans"} color={"#8B8B8B"} display={"inline"}>{`${t ("Experiment name")}:`}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        labelId="exp-name-label"
                                        id="exp-name"
                                        value={experiment?.name}
                                        placeholder={t("Give a representative name to your experiment")}
                                        onChange={(event)=>{
                                            const exp = {...experiment}
                                            exp.name = event.target.value
                                            setExperiment(exp);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography fontFamily={"Noto Sans"} color={"#8B8B8B"} display={"inline"}>{`${t ("Selected games")}:`}</Typography>
                                    <Box style={{marginTop: '20px', marginBottom: '20px', border: '1px solid', borderColor: '#ddd', borderRadius:'8px'}}>
                                        <DraggableList 
                                            items={questionsGame} 
                                            onDragEnd={()=>{}}
                                            renderItemFn={(item, index, key) => {return (
                                                <GameDraggableListItem 
                                                    item={item} 
                                                    editable={item.parametrizable} 
                                                    onEdit={()=>{editGameParameters(item, 0)}}
                                                    deletable={false} 
                                                />
                                            )}}
                                        />
                                    </Box>
                                    <Box style={{marginBottom: '50px', border: '1px solid', borderColor: '#ddd', borderRadius:'8px'}}>
                                        <DraggableList 
                                            items={sortableGames} 
                                            onDragEnd={onDragEnd}
                                            renderItemFn={(item, index, key) => {
                                                return (
                                                    <GameDraggableListItem 
                                                        item={item} 
                                                        isPaid={item.isPaid}
                                                        payable={item.payable}
                                                        editable={item.parametrizable} 
                                                        deletable 
                                                        sortable 
                                                        onPay={()=>{
                                                            if(!probPaymentAllGames){
                                                                item.payable = !item.payable
                                                                setSortableGames([...sortableGames])
                                                            }
                                                            else{
                                                                setShowSnackPayGame(true);
                                                            }
                                                        }}
                                                        onPinnedToggle={()=>{
                                                            item.pinned = !item.pinned;
                                                            setSortableGames([...sortableGames])}}
                                                        onEdit={()=>{editGameParameters(item, index+1)}}
                                                        onDelete={()=>{
                                                            const idx = sortableGames.indexOf(item);
                                                            sortableGames.splice(idx,1)
                                                            setSortableGames([...sortableGames])
                                                        }}
                                                    />)
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} style={{display: 'flex', justifyContent:'center'}}>
                                    <Button 
                                        sx={{fontSize:'16px', height:'30px'}}
                                        variant='contained' 
                                        onClick={() => {
                                            navigate("/newExperiment", props.location.state)
                                        }}
                                    >
                                        <p><Trans>Add more games</Trans></p>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} key={`experiment-options`}>
                            <Typography fontFamily={"Noto Sans"} color={"#8B8B8B"} display={"inline"}>{`${t ("Experiment options")}:`}</Typography>
                            <Box style={{marginTop: '20px', marginBottom: '20px', border: '1px solid', borderColor: '#ddd', borderRadius:'8px', padding: '16px'}}>
                                <Grid container spacing={1}>
                                    {/* ORDER BUTTONS */}
                                    <Grid item xs={12}>
                                        <Typography fontFamily={"Noto Sans"} color={"#8B8B8B"} display={"inline"} fontSize={"0.85em"}>{`${t ("Order of games")}:`}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button 
                                            sx={{fontSize:'16px', height:'30px', backgroundColor:'#FFD452', color:'#000'}}
                                            variant='contained' 
                                            fullWidth
                                            onClick={() => {sortableGames.forEach(game => game.pinned = true); setSortableGames([...sortableGames]);}}
                                        >
                                            <p><Trans>All fixed</Trans></p>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button 
                                            sx={{fontSize:'16px', height:'30px'}}
                                            variant='contained' 
                                            fullWidth
                                            onClick={() => {
                                                sortableGames.forEach(game => game.pinned = false); 
                                                setSortableGames([...sortableGames]);
                                            }}
                                        >
                                            <p><Trans>All random</Trans></p>
                                        </Button>
                                    </Grid>
                                    {/* LANGUAGE */}
                                    <Grid item xs={6}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography fontFamily={"Noto Sans"} color={"#8B8B8B"} display={"inline"} fontSize={"0.85em"}>{`${t ("Language of the experiment")}:`}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="language-label"
                                                        id="language"
                                                        value={experiment?.language}
                                                        onChange={(event)=>{
                                                            const exp = {...experiment}
                                                            exp.language = event.target.value
                                                            setExperiment(exp);
                                                        }}
                                                    >
                                                        {
                                                            Object.keys(supportedLanguages).map((lang) => (
                                                                <MenuItem value={lang}>{supportedLanguages[lang].label}</MenuItem>
                                                            ))
                                                        } 
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* MAX PARTICIPANTS */}
                                    <Grid item xs={6}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography fontFamily={"Noto Sans"} color={"#8B8B8B"} display={"inline"} fontSize={"0.85em"}>{`${t ("Maximum number of participants")}:`}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        error={experiment?.maxParticipants <= 0}
                                                        helperText={experiment?.maxParticipants <= 0 && t ("The number of participants must be greater than zero")}
                                                        labelId="max-participants-label"
                                                        id="max-participants"
                                                        type="number"
                                                        value={experiment?.maxParticipants}
                                                        onChange={(event)=>{if(event.target.value >= 0){
                                                            const exp = {...experiment}
                                                            exp.maxParticipants = Number(event.target.value)
                                                            setExperiment(exp);
                                                        }}}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* EXTERNAL CODE */}
                                    <Grid item xs={12} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <Typography fontFamily={"Noto Sans"} color={"#8B8B8B"} display={"inline"} fontSize={"0.85em"}>{`${t ("External code")}:`}</Typography>
                                        <Tooltip 
                                            title={t("This code will be delivered to your participants once they complete the experiment")}
                                            style={{marginLeft: '16px'}}
                                        >
                                            <HelpRoundedIcon fontSize="small" color="info"/>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <RadioGroup
                                                aria-labelledby="ext-code-buttons-group-label"
                                                defaultValue="none"
                                                value={experiment?.externalCodeChoice}
                                                name="ext-code-radio-buttons-group"
                                                onChange={(event)=>{
                                                    const exp = {...experiment}
                                                    exp.externalCodeChoice = event.target.value
                                                    setExperiment(exp);
                                                }}
                                            >
                                                <FormControlLabel value="none" control={<Radio />} label={t ("None")} />
                                                <FormControlLabel value="userId" control={<Radio />} label={t ("User ID")} />
                                                <FormControlLabel value="completionCode" control={<Radio />} label={t ("Completion code")} />
                                            </RadioGroup>
                                            <TextField
                                                disabled={experiment?.externalCodeChoice !== "completionCode"}
                                                labelId="completion-code-label"
                                                id="completion-code"
                                                value={experiment?.completionCode}
                                                placeholder={t("Define a custom completion code for your experiment")}
                                                onChange={(event)=>{
                                                    const exp = {...experiment}
                                                    exp.completionCode = event.target.value
                                                    setExperiment(exp);
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {/* PUBLISH STATUS */}
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography fontFamily={"Noto Sans"} color={"#8B8B8B"} display={"inline"} fontSize={"0.85em"}>{`${t ("Do you want your participants to be able to access this experiment once it's created?")}`}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                <RadioGroup
                                                    aria-labelledby="publish-status-buttons-group-label"
                                                    defaultValue={true}
                                                    value={experiment?.openToParticipants}
                                                    name="publish-status-radio-buttons-group"
                                                    onChange={(event)=>{
                                                        const exp = {...experiment}
                                                        exp.openToParticipants = event.target.value === 'true'
                                                        setExperiment(exp);
                                                    }}
                                                >
                                                    <FormControlLabel value={true} control={<Radio />} label={t ("Yes")} />
                                                    <FormControlLabel value={false} control={<Radio />} label={t ("No, I'll open it later")} />                                    
                                                </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* CONSENT TEXT */}
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography fontFamily={"Noto Sans"} color={"#8B8B8B"} display={"inline"} fontSize={"0.85em"}>{`${t ("Text added inside mandatory consent text to explain experiment to participants")}:`}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                <TextField
                                                        labelId="consent-text-label"
                                                        id="consent-text"
                                                        value={experiment?.consentText}
                                                        multiline
                                                        placeholder={t("This text will be shown alongside the confirmed consent to all the participants")}
                                                        onChange={(event)=>{
                                                            const exp = {...experiment}
                                                            exp.consentText = event.target.value
                                                            setExperiment(exp);
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* PROBABILISTIC PAYMENT */}
                                    { (paidGames > 0) ?
                                        <>
                                            <Grid item xs={12}>
                                                <Typography fontFamily={"Noto Sans"} color={"#8B8B8B"} display={"inline"} fontSize={"0.85em"}>{`${t ("Probabilistic payment")}`}</Typography>
                                                <Tooltip 
                                                    title={<React.Fragment> <Trans>You can reduce the payments of your experiment by selecting</Trans>: <ul><li><Trans>Pay only a percentage of your participants, and/or</Trans></li> <li><Trans>Pay only some selected games</Trans></li></ul></React.Fragment>}
                                                    style={{marginLeft: '16px'}}
                                                >
                                                    <HelpRoundedIcon fontSize="small" color="info"/>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl  sx = {{display: 'flex', flexDirection: 'row', alignItems: 'center'}} fullWidth>
                                                    <FormControlLabel control={
                                                        <Switch 
                                                            defaultChecked={probPaymentAllParticipants} 
                                                            onChange={(event)=>{setProbPaymentAllParticipants(event.target.checked);}}
                                                        />
                                                        } 
                                                        label={probPaymentAllParticipants ? t("Pay all participants") : t("Pay some participants") } 
                                                    />
                                                    {!probPaymentAllParticipants ?
                                                        <Box sx={{ width: 300 }}>
                                                            <Slider 
                                                                defaultValue={probPaymentParticipantsRate} 
                                                                valueLabelDisplay="on"
                                                                valueLabelFormat={value => `${value}%`}
                                                                onChange={(event)=>{setProbPaymentParticipantsRate(event.target.value);}}
                                                            />
                                                        </Box>
                                                    :
                                                        null
                                                    }
                                                </FormControl>
                                            </Grid>
                                            
                                            <Grid item xs={12}>
                                                <FormControl  sx = {{display: 'flex', flexDirection: 'row', alignItems: 'center'}} fullWidth>
                                                    <FormControlLabel control={
                                                        <Switch 
                                                            defaultChecked={probPaymentAllGames} 
                                                            onChange={(event)=>{
                                                                setProbPaymentAllGames(event.target.checked);
                                                                if(event.target.checked){
                                                                    sortableGames.forEach(game => game.payable = true); 
                                                                    setSortableGames([...sortableGames]);
                                                                }
                                                            }}
                                                        />
                                                        } 
                                                        label={probPaymentAllGames ? t("Pay all games") : t("Pay some games")} 
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </>
                                        : 
                                        null
                                    }
                                    {/* OTHER OPTIONS */}
                                    <Grid item xs={12}>
                                        <Typography fontFamily={"Noto Sans"} color={"#8B8B8B"} display={"inline"} fontSize={"0.85em"}>{`${t ("Other options")}:`}</Typography>
                                    </Grid>
                                    {/* EMAIL */}
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <FormControlLabel control={
                                                <Checkbox 
                                                    defaultChecked
                                                    checked={experiment?.askEmail}
                                                    onChange={(event)=>{
                                                        const exp = {...experiment}
                                                        exp.askEmail = event.target.checked
                                                        setExperiment(exp);
                                                    }} 
                                                    />
                                                } 
                                                label={t("Ask for email to your participants at the end of the experiment")} 
                                                />
                                        </FormControl>
                                    </Grid>
                                    {/* ACTION BUTTONS */}
                                    <Grid item xs={6}>
                                        <Button 
                                            sx={{fontSize:'16px', height:'30px',}}
                                            variant='contained' 
                                            color={'error'}
                                            fullWidth
                                            onClick={() => {setDiscardDialogOpen(true)}}
                                        >
                                            {editing ?
                                                <p><Trans>Discard changes</Trans></p>
                                            :
                                                <p><Trans>Discard experiment</Trans></p>
                                            }
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button 
                                            disabled={!authContextValue?.userData?.isAdminValidated || !experiment?.name}
                                            sx={{fontSize:'16px', backgroundColor:'#28A745', height:'30px'}}
                                            variant='contained' 
                                            fullWidth
                                            onClick={saveExperiment}
                                        >
                                            {editing ?
                                                <p><Trans>Update experiment</Trans></p>
                                            :
                                                <p><Trans>Save experiment</Trans></p>
                                            }
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </BeGeneralPage>
			</main>
            <Dialog
                open={discardDialogOpen}
                onClose={() => {setDiscardDialogOpen(false)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {editing ?
                        t("Do you really want to discard your changes?")
                    :
                        t("Do you really want to discard your experiment?")
                    }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {editing ?
                            `${t("All the changes on your experiment will be lost")}. ${t("Do you want to proceed?")}`
                        :
                            `${t("All the games and parameters you've defined will be lost")}. ${t("Do you want to proceed?")}`
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setDiscardDialogOpen(false)}} autofocus>{t("Cancel")}</Button>
                    <Button onClick={discardExperiment}>
                        {editing ?
                            t("Discard changes")
                        :
                            t("Discard experiment")
                        }
                    </Button>
                </DialogActions>
            </Dialog>
		</ThemeProvider>
	);
};

export default CheckoutExperiment;

export const graphQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "side_bar", "checkout_experiment", "game_draggable_list_item", "new_experiment"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
