import * as React from "react"
import { 
    Grid,
    IconButton,
    Tooltip,
} from "@mui/material";
import { graphql } from 'gatsby'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import PushPinRoundedIcon from '@mui/icons-material/PushPinRounded';
import ShuffleRoundedIcon from '@mui/icons-material/ShuffleRounded';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';

const GameDraggableListItem = ({item: game, deletable, sortable, isPaid, payable, editable, onPinnedToggle, onPay, onEdit, onDelete}) => {
    const { i18n } = useTranslation();
    const { t } = useTranslation();

    return (
        <Grid container>
            <Grid item xs={1}>
                {sortable && 
                    <IconButton size='large'>
                        <DragIndicatorRoundedIcon/>
                    </IconButton>
                }
            </Grid>
            <Grid item xs={8}>
                <div style={{display: 'flex'}}>
                    <Tooltip title={game.pinned ? t('Game in fix order') : t('Game in random order')}>
                        <IconButton onClick={onPinnedToggle} size='large'>
                            {game.pinned ?
                                <PushPinRoundedIcon/>
                                :
                                <ShuffleRoundedIcon/>
                            }
                        </IconButton>
                    </Tooltip>
                    <p>{game.title}</p>
                </div>
            </Grid>
            <Grid item xs={1}>
                <div style={{display: 'flex'}}>
                {isPaid &&
                    <Tooltip title={payable ? t('This game will be paid') : t("This game won't be paid")}>
                        <IconButton size='large' onClick={onPay} color={payable ? 'success' : 'warning'}>
                            <MonetizationOnRoundedIcon/>
                        </IconButton>
                    </Tooltip>
                }
                </div>
            </Grid> 
            <Grid item xs={1}>
                <div style={{display: 'flex'}}>
                    {editable &&
                        <Tooltip title={t("Edit game parameters")}>
                            <IconButton size='large' onClick={onEdit}>
                                <EditRoundedIcon/>
                            </IconButton>
                        </Tooltip>
                    }
                </div>
            </Grid> 
            <Grid item xs={1}>
                <div style={{display: 'flex'}}>
                    {deletable && 
                        <Tooltip title={t("Remove this game from experiment")}>
                            <IconButton size='large' onClick={onDelete}>
                                <DeleteRoundedIcon/>
                            </IconButton>
                        </Tooltip>
                    }
                </div>
            </Grid>
            
        </Grid>
    )
}

export default GameDraggableListItem;