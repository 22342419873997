import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import ListItem from '@mui/material/ListItem';


const noDraggingItemStyle = {
    width: '100%', 
}

const draggingItemStyle = {
    ...noDraggingItemStyle,
    background: 'rgb(235,235,235)', 
}

const DraggableListItem = ({ item, index, renderItemFn }) => {
  return (
    <Draggable draggableId={item.listId} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={snapshot.isDragging ? draggingItemStyle : noDraggingItemStyle}
        >
          {renderItemFn(item, index, item.listId)}
        </ListItem>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
